*::-webkit-scrollbar {
  width: 0.4em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  border-radius: 0.2em;
  background-color: rgba(80, 77, 77, 0.1);
  outline: 1px solid slategrey;
}
.leaflet-popup-content-wrapper,
.leaflet-popup.tip {
  background-color: blue;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: '100%'; 
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
    counter-increment: page;
    content: counter(page);
  }
}

@page {
  display: block;
  size: 257mm 364mm;
  margin: 4mm 4mm 10mm 4mm;
}

div.flexible-modal {
  position: absolute;
  z-index: 10001;
  border: none;
  background: white;
}
div.flexible-modal-mask {
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
div.flexible-modal-drag-area-left,
div.flexible-modal-drag-area-right,
div.flexible-modal-drag-area-bottom {
  display: none;
}

div.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: none;
  border-right: none;
}
div.flexible-modal-drag-area {
  background-color: transparent;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}
div.flexible-modal-drag-area-left {
  height: 0px;
}
div.my-modal-custom-class {
  background-color: transparent;
}
.leaflet-interactive:focus {
  outline: none;
}
.leaflet-pixi-overlay {
  z-index: 201;
}
.leaflet-popup-close-button {
  margin: 5px;
}

[data-rmiz-modal-overlay='visible'] {
  background: rgba(0, 0, 0, 0);
}
.custom-zoom [data-rmiz-modal-overlay],
.custom-zoom [data-rmiz-modal-img] {
  margin: '16px';
  border: '1px solid red';
}

.leaflet-popup-scrolled {
  overflow-y: hidden;
}