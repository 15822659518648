.dropzone {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
}

.dropzone p:hover {
  color: #f6f6f6;
}

section p {
  color: #969696;
  text-align: center;
  transition: color .2s ease-in-out;
}

section footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 

aside h4 {
  margin: 10px;
}
