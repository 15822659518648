div.flexible-modal {
  position: absolute;
  z-index: 620;
  border: none;
  background: default;
}
div.flexible-modal-mask {
  position: fixed;
  background: transparent;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: -1;
}

div.flexible-modal-drag-area-left, 
div.flexible-modal-drag-area-right, 
div.flexible-modal-drag-area-bottom{
  display: none;
}

div.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: none;
  border-right: none;
}
div.flexible-modal-drag-area{
  background-color: transparent;
  height: 40px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
div.flexible-modal-drag-area-left{
  height: 0px;
}
div.my-modal-custom-class{
  background-color: transparent;
}