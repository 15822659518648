div.logo {
    display: flex;
    align-items: center;
    img {
        display: inline-flex;
    }
    h3 {
        display: inline-flex;
        margin-left: 5px;
        color: teal;
    }
}